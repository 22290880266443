import axios from 'axios'
import { API2_URL, API_COLLECTOR_URL, API_URL, BUILD_VERSION } from '~/config'

axios.interceptors.request.use((config) => {
  config.params = { ...config.params, build: BUILD_VERSION }
  return config
}, (error) => {
  return Promise.reject(error)
})

// current validators data
export type ValidatorStats = {
  voteId: string
  validatorId: string
  fee: number
  apy: number
  apyEst: number
  totalStake: number | string
  network: string
  name: string | undefined
  details: string | undefined
  website: string | undefined
  keybaseUsername: string | undefined
  iconUrl: string | undefined
  inTop33: boolean
  inJpool: boolean
  isDelinquent: boolean
  svName: string
  apyComparedMax: number
  jpoolLamports: number | string
  nodeVersion: string
  lastVote: string
  leaderSlotsTotal: number
  leaderSlotsDone: number
  leaderSlotsEpoch: number
  tvCredits: number
  tvcRank: number
}

export type DcData = {
  asn: number
  aso: string
  city?: string
  continent: string
  coordinates: [number, number]
  country: string
  country_iso: string
}

export type StakeConcentration = {
  dc: number
  asn: number
  city: number
  country: number
}

export type ScoreInfo = {
  apy3_score: number
  apy10_score: number
  apy_score: number
  asn_concentration_score: number
  city_concentration_score: number
  commission_score: number
  country_concentration_score: number
  dc_concentration_score: number
  info_score: number
  invalid_version_penalty: number
  jito_score: number
  skip_rate_score: number
  stake_weight_score: number
  superminority_penalty: number
  svt_member_score: number
  uptime_score: number
  vote_success_score: number
  withdraw_authority_score: number
}

// new api data
export type ValidatorInfo = {
  activated_stake: number
  apy: number
  blocks_produced: number
  comment?: string
  commission: number
  created_at: string
  credits: number
  data_center?: DcData
  delinquent: boolean
  details?: string
  downtime: number
  epoch: number
  identity: string
  image?: string
  is_jito: boolean
  is_jpool: boolean
  is_poor_performer: boolean
  is_svt: boolean
  jito_apy: number
  jito_commission_bps: number
  jpool_score: number
  jpool_stake: number
  keybase?: string
  leader_slots: number
  name?: string
  node_ip?: string
  score_info: ScoreInfo
  skip_rate: number
  stake_concentration: StakeConcentration
  stake_to_become_superminority: number
  stake_weight: number
  superminority: boolean
  tpu_ip: string
  updated_at: string
  uptime: number
  version: string
  vote_account: string
  website?: string
  wiz_score: number
}

type CoinStats = {
  ath: number
  ath_change_percentage: number
  ath_date: string
  atl: number
  atl_change_percentage: number
  atl_date: string
  circulating_supply: number
  current_price: number
  fully_diluted_valuation: null
  high_24h: number
  id: string
  image: string
  last_updated: string
  low_24h: number
  market_cap: number
  market_cap_change_24h: number
  market_cap_change_percentage_24h: number
  market_cap_rank: number
  // max_supply: null,
  name: string
  price_change_24h: number
  price_change_percentage_24h: number
  price_change_percentage_24h_in_currency: number
  // roi: null,
  symbol: string
  total_supply: number
  total_volume: number
}

export type EpochInfo = {
  epoch: number
  network?: string
  absoluteSlot: number
  blockHeight?: number
  slotIndex: number
  slotsInEpoch: number
  transactionCount?: number
}
export type TpsInfo = {
  tps: number
  hourlySlotTime: number
}
export type ClusterInfo = EpochInfo & TpsInfo

type AprTvl = {
  apr?: number
  tvl?: number
}

export type DefiValues = Record<string, AprTvl>

export type JpoolScore = {
  voteId: string
  isJpoolValidator: boolean
  jpoolScore: number
  jpoolRank: number
  svtMemberScore: number
  vaTotalScore: number
  vaStakePercentScore: number
  vaPublishedInformationScore: number
  apyScore: number
  apy3Score: number
  apy10Score: number
  scAsnScore: number
  scDcScore: number
  scCountryScore: number
  scCityScore: number
  publishedInformationScore: number
  desiredStake: string
  dropReason: string
}

export type ValidatorStakeInfo = {
  epoch: number
  vote_account: string
  stake_type: string
  stake_amount: number
  createdAt: string
}

export async function getValidators(network: string): Promise<Array<ValidatorStats>> {
  const resp = await axios.get(
    `${API_COLLECTOR_URL}/validators/list?network=${network}&select=voteId,validatorId,fee,apy,apyEst,totalStake,name,details,website,keybaseUsername,iconUrl,inTop33,inJpool,isDelinquent,svName,apyComparedMax,jpoolLamports,nodeVersion,lastVote,leaderSlotsTotal,leaderSlotsDone,leaderSlotsEpoch,tvcRank,tvCredits`,
  )
  return resp.data.data
}

export async function getNewEpochInfo(epoch: number | string): Promise<NewEpochInfo> {
  const resp = await axios.get(
    `${API_URL}/epoch/${epoch}`,
  )
  return resp.data.data
}

type NewEpochInfo = {
  epoch: number
  sol_supply: string
  sol_circulating: number
  inflation: number
  inflation_tapper: number
  started_at: string
  ended_at: string
  created_at: string
}

export async function getValidatorsInfo(): Promise<Array<ValidatorInfo>> {
  const resp = await axios.get(
    `${API_URL}/validators?fields=apy,jito_apy,stake_concentration,data_center,node_ip,jito_commission_bps,skip_rate`,
  )
  return resp.data
}

export async function getValidatorsStakeInfo(vote: string, stakeType?: string): Promise<Array<ValidatorStakeInfo>> {
  const resp = await axios.get(
    `${API_URL}/delegation?vote=${vote}${stakeType ? `&stake_type=${stakeType}` : ''}`,
  )
  return resp.data
}

export async function getClusterStakeInfo(): Promise<Array<ValidatorStakeInfo>> {
  const resp = await axios.get(
    `${API_URL}/delegation`,
  )
  return resp.data
}

export async function getCoinStats(): Promise<Record<string, CoinStats>> {
  const resp = await axios.get(
    `${API_COLLECTOR_URL}/coins`,
  )
  return resp.data.data
}

export async function getEpochInfo(network: string): Promise<ClusterInfo> {
  const resp = await axios.get(
    `${API_COLLECTOR_URL}/current-epochs/${network}`,
  )
  return resp.data.data
}

export async function getDefi(): Promise<DefiValues> {
  const resp = await axios.get(
    `${API_COLLECTOR_URL}/defi`,
  )
  return resp.data.data
}

export async function getWithdrawList(): Promise<Record<string, number>> {
  const resp = await axios.get(
    `${API2_URL}/withdrawal-list`,
  )
  const data = resp.data as Array<{ voteId: string, stake: number }>
  return data.reduce((map, item) => {
    map[item.voteId] = item.stake
    return map
  }, {})
}

export type StakePoolFees = {
  epochFee: number
  stakeDepositFee: number
  solDepositFee: number
  withdrawalFee: number
  solWithdrawalFee: number
  nextStakeWithdrawalFee: number
  nextSolWithdrawalFee: number
  nextEpochFee: number
  solReferralFee: number
  stakeReferralFee: number
}

export type StakePoolData = {
  poolAddress: string
  poolMint: string
  reserveStakeAccount: string
  poolTokenSupply: number
  totalLamports: number
  exchangeRate: number
  fees: StakePoolFees
  reserveStakeBalance: number
  minRentBalance: number
  connectionLost: boolean
}

export async function getStakePoolData(): Promise<StakePoolData> {
  const resp = await axios.get(
    `${API2_URL}/pool-info`,
  )
  console.log('[StakePool] resp:', resp)
  return resp.data
}

export async function getValidatorsJpoolScores(epoch: number): Promise<JpoolScore[]> {
  const resp = await axios.get(
    `${API_COLLECTOR_URL}/jpool-scores/${epoch}?select=voteId,jpoolScore,jpoolRank,svtMemberScore,desiredStake,dropReason,vaTotalScore,vaStakeConcentrationScore,vaPublishedInformationScore,apyScore,apy3Score,apy10Score,scAsnScore,scDcScore,scCountryScore,scCityScore,publishedInformationScore`,
  )
  return resp.data.data
}

// Stakes
export async function checkWalletInDelegators(wallet: string) {
  const resp = await axios.get(
    `${API_COLLECTOR_URL}/delegator-wallets/${wallet}`,
  )
  return resp.data
}

export async function addToDelegators(wallet: string, network = 'mainnet') {
  const resp = await axios.post(
    `${API_COLLECTOR_URL}/delegator-wallets/${wallet}${network ? `?network=${network}` : ''}`,
  )
  return resp.data
}

export async function getWalletRewards(wallet: string, network = 'mainnet') {
  const resp = await axios.get(
    `${API_COLLECTOR_URL}/stake-accounts/list?wallet=${wallet}&network=${network}`,
  )
  return resp.data
}

export async function getStakesStatus(wallet: string, network = 'mainnet') {
  const resp = await axios.get(
    `${API_COLLECTOR_URL}/stake-accounts/checkCompleteness?wallet=${wallet}&network=${network}`,
  )
  return resp.data
}

// Validator page api
export type GetValidatorHistoryProps = {
  voteKey?: string
  identity?: string
  network?: string
  epochFrom?: number
  epochCount?: number
}

export type ApyStats = {
  apy: number
  epoch: number
}

export type ValidatorHistory = {
  apy: number
  epoch: number
  fee: number
  totalStake: number
  commissionReward: number | string
  votingReward: number | string
  votingFee: number | string
  votingCompensation: number | string
  jitoReward: number | string
  skippedSlots: number
  leaderSlotsDone: number
  leaderSlotsTotal: number
  tvCredits: number
  tvcRank: number
}

export type EpochData = {
  id: number
  epoch: number
  network: string
  firstSlot: number
  lastSlot: number
  firstBlock: number
  lastBlock: number | null
  startTime: string
  endTime: string
  createdAt: string
}

export type ValidatorVotingStats = {
  validatorId: string
  voteId: string
  // network: string
  epoch: number
  identityBalance: number | string
  isDelinquent: boolean
  epochCredits: number
  tvCredits: number
  tvcRank: number
  slotIndex: number
  leaderSlotsTotal: number
  leaderSlotsDone: number
  createdAt: string
}

export type MeanSlot = {
  createdAt: string
  epoch: number
  id: number
  leaderSlotsDone: number
  skippedSlots: number
  avgTvCredits: number
  rank1Tvc: number
  rank200Tvc: number
  network: number
  slotIndex: number
}

/**
 * validator statistics by epoch
 */
export async function getValidatorHistory({
  voteKey,
  network,
  epochFrom,
  epochCount = 30,
}: GetValidatorHistoryProps): Promise<Array<ValidatorHistory>> {
  if (!voteKey) {
    throw new Error('voteKey prop is required to getValidatorHistory')
  }
  const resp = await axios.get(
    `${API_COLLECTOR_URL}/validators-history/history?network=${network ?? 'mainnet'}${voteKey ? `&vote_id=${voteKey}` : ''}&epoch_count=${epochCount}${epochFrom ? `&epoch_from=${epochFrom}` : ''}`,
  )
  return resp.data.data
}

/**
 * validator voting statistics by time
 */
export async function getValidatorVotingStats(voteKey: string, network = 'mainnet'): Promise<Array<ValidatorVotingStats>> {
  const resp = await axios.get(
    `${API_COLLECTOR_URL}/stats-validators/${network}?vote_id=${voteKey}&days=1`,
  )
  return resp.data.data
}

/**
 * cluster leader slots history by epochs
 */
export async function getAvgSlotsHistory(network = 'mainnet', epoch?: number): Promise<Array<MeanSlot>> {
  const resp = await axios.get(
    `${API_COLLECTOR_URL}/mean-slots-data/history?network=${network}&epoch_count=30${epoch ? `&epoch_from=${epoch}` : ''}`,
  )
  return resp.data.data
}

/**
 * cluster leader slots for 24h by 10min interval
 */
export async function getAvgSlotsCurrent(network = 'mainnet', days = 1): Promise<Array<MeanSlot>> {
  const resp = await axios.get(
    `${API_COLLECTOR_URL}/mean-slots-data/?network=${network}&days=${days}`,
  )
  return resp.data.data
}

/**
 * the latest leader slots data for a cluster
 */
export async function getAvgSlotsLast(network = 'mainnet', epoch: number): Promise<MeanSlot> {
  const resp = await axios.get(
    `${API_COLLECTOR_URL}/mean-slots-data?last=true&network=${network}&epoch=${epoch}`,
  )
  return resp.data.data
}

export async function getAverageApy(): Promise<Array<ApyStats>> {
  const resp = await axios.get(
    `${API_COLLECTOR_URL}/apy-average/history`,
  )
  return resp.data.data
}

export async function getEpochByTime(time: number, network = 'mainnet'): Promise<EpochData> {
  const resp = await axios.get(
    `${API_COLLECTOR_URL}/epochs/epoch-by-timestamp/${time}/${network}`,
  )
  return resp.data.data
}

export async function getEpochs(epochs: string, network = 'mainnet'): Promise<EpochData[]> {
  const resp = await axios.get(
    `${API_COLLECTOR_URL}/epochs/${network}?epochs=${epochs}`,
  )
  return resp.data.data
}

export type CoinHistory = {
  name: string
  close: number
  epoch: number
}

export async function getCoinHistory({ epoch, id = 'solana', network }: { epoch: number | string, id?: string, network?: string }): Promise<CoinHistory[]> {
  const resp = await axios.get(
    `${API_COLLECTOR_URL}/coins-history/${id}?epoch=${epoch}${network ? `&network=${network}` : ''}`,
  )
  return resp.data.data
}

export async function getCoinPriceByTime(time: number, id = 'solana'): Promise<CoinHistory> {
  const resp = await axios.get(
    `${API_COLLECTOR_URL}/coins-history/${id}/${time}`,
  )
  return resp.data.data
}

type CoinPeriodPriceItem = {
  time: number
  open: number
  high: number
  low: number
  close: number
}

export async function getCoinPeriodPrice(
  id = 'solana',
): Promise<Array<CoinPeriodPriceItem>> {
  const resp = await axios.get(
    `${API_COLLECTOR_URL}/coins/history/${id}`,
  )
  return resp.data.data
}
