import type { UserModule } from '~/types'
import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import I18NextVue from 'i18next-vue'

const resources = Object.fromEntries(
  Object.entries(import.meta.glob<{ default: any }>('~/locales/*.json', { eager: true }))
    .map(([k, v]) => [k.split('/').pop()!.replace('.json', '').toLowerCase(), { translation: v }]),
)

export const install: UserModule = ({ app }) => {
  i18next
    .use(LanguageDetector)
    .init({
      // debug: import.meta.env.DEV,
      fallbackLng: 'en',
      resources,
    })
    .catch()
  app.use(I18NextVue, { i18next })
}
