<script lang="ts" setup>
import { evaMoon, evaSun } from '@quasar/extras/eva-icons'
import { Dark } from 'quasar'
import { isDark } from '~/hooks'

watch(isDark, Dark.set)
</script>

<template>
  <q-toggle
    v-model="isDark"
    class="styled-toggle"
    toggle-order="tf"
    color="primary-gray"
    keep-color
    label=""
    :checked-icon="evaMoon"
    :unchecked-icon="evaSun"
  />
</template>
