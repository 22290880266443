export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/home/runner/work/jpool-webapp/jpool-webapp/src/pages/index.vue'),
    /* no children */
  },
  {
    path: '/:all(.*)',
    name: '/[...all]',
    component: () => import('/home/runner/work/jpool-webapp/jpool-webapp/src/pages/[...all].vue'),
    /* no children */
    meta: {
      "layout": "empty"
    }
  },
  {
    path: '/about',
    name: '/about',
    component: () => import('/home/runner/work/jpool-webapp/jpool-webapp/src/pages/about.vue'),
    /* no children */
  },
  {
    path: '/defi',
    name: '/defi',
    component: () => import('/home/runner/work/jpool-webapp/jpool-webapp/src/pages/defi.vue'),
    /* no children */
  },
  {
    path: '/flex-staking',
    /* internal name: '/flex-staking' */
    /* no component */
    children: [
      {
        path: '',
        name: '/flex-staking/',
        component: () => import('/home/runner/work/jpool-webapp/jpool-webapp/src/pages/flex-staking/index.vue'),
        /* no children */
      },
      {
        path: ':vote',
        /* internal name: '/flex-staking/[vote]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/flex-staking/[vote]/',
            component: () => import('/home/runner/work/jpool-webapp/jpool-webapp/src/pages/flex-staking/[vote]/index.vue'),
            /* no children */
          },
          {
            path: 'report',
            name: '/flex-staking/[vote]/report',
            component: () => import('/home/runner/work/jpool-webapp/jpool-webapp/src/pages/flex-staking/[vote]/report.vue'),
            /* no children */
          },
          {
            path: 'stake-report',
            name: '/flex-staking/[vote]/stake-report',
            component: () => import('/home/runner/work/jpool-webapp/jpool-webapp/src/pages/flex-staking/[vote]/stake-report.vue'),
            /* no children */
          }
        ],
      }
    ],
  },
  {
    path: '/high-yield',
    name: '/high-yield',
    component: () => import('/home/runner/work/jpool-webapp/jpool-webapp/src/pages/high-yield.vue'),
    /* no children */
  },
  {
    path: '/holders-club',
    /* internal name: '/holders-club' */
    /* no component */
    children: [
      {
        path: '',
        name: '/holders-club/',
        component: () => import('/home/runner/work/jpool-webapp/jpool-webapp/src/pages/holders-club/index.vue'),
        /* no children */
        meta: {
          "layout": "holders-club"
        }
      },
      {
        path: 'about',
        name: '/holders-club/about',
        component: () => import('/home/runner/work/jpool-webapp/jpool-webapp/src/pages/holders-club/about.vue'),
        /* no children */
        meta: {
          "layout": "holders-club"
        }
      },
      {
        path: 'boosters',
        name: '/holders-club/boosters',
        component: () => import('/home/runner/work/jpool-webapp/jpool-webapp/src/pages/holders-club/boosters.vue'),
        /* no children */
        meta: {
          "layout": "holders-club"
        }
      },
      {
        path: 'more',
        name: '/holders-club/more',
        component: () => import('/home/runner/work/jpool-webapp/jpool-webapp/src/pages/holders-club/more.vue'),
        /* no children */
        meta: {
          "layout": "holders-club"
        }
      },
      {
        path: 'profile',
        name: '/holders-club/profile',
        component: () => import('/home/runner/work/jpool-webapp/jpool-webapp/src/pages/holders-club/profile.vue'),
        /* no children */
        meta: {
          "layout": "holders-club"
        }
      },
      {
        path: 'referrals',
        name: '/holders-club/referrals',
        component: () => import('/home/runner/work/jpool-webapp/jpool-webapp/src/pages/holders-club/referrals.vue'),
        /* no children */
        meta: {
          "layout": "holders-club"
        }
      },
      {
        path: 'rewards',
        name: '/holders-club/rewards',
        component: () => import('/home/runner/work/jpool-webapp/jpool-webapp/src/pages/holders-club/rewards.vue'),
        /* no children */
        meta: {
          "layout": "holders-club"
        }
      },
      {
        path: 'staking',
        name: '/holders-club/staking',
        component: () => import('/home/runner/work/jpool-webapp/jpool-webapp/src/pages/holders-club/staking.vue'),
        /* no children */
        meta: {
          "layout": "holders-club"
        }
      }
    ],
  },
  {
    path: '/impressum',
    name: '/impressum',
    component: () => import('/home/runner/work/jpool-webapp/jpool-webapp/src/pages/impressum.vue'),
    /* no children */
  },
  {
    path: '/jstaking',
    /* internal name: '/jstaking' */
    /* no component */
    children: [
      {
        path: '',
        name: '/jstaking/',
        component: () => import('/home/runner/work/jpool-webapp/jpool-webapp/src/pages/jstaking/index.vue'),
        /* no children */
      },
      {
        path: 'report',
        name: '/jstaking/report',
        component: () => import('/home/runner/work/jpool-webapp/jpool-webapp/src/pages/jstaking/report.vue'),
        /* no children */
      },
      {
        path: 'stake-report',
        name: '/jstaking/stake-report',
        component: () => import('/home/runner/work/jpool-webapp/jpool-webapp/src/pages/jstaking/stake-report.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/pool-info',
    name: '/pool-info',
    component: () => import('/home/runner/work/jpool-webapp/jpool-webapp/src/pages/pool-info.vue'),
    /* no children */
  },
  {
    path: '/privacy',
    name: '/privacy',
    component: () => import('/home/runner/work/jpool-webapp/jpool-webapp/src/pages/privacy.vue'),
    /* no children */
  },
  {
    path: '/stake-concentration',
    name: '/stake-concentration',
    component: () => import('/home/runner/work/jpool-webapp/jpool-webapp/src/pages/stake-concentration.vue'),
    /* no children */
  },
  {
    path: '/stake-distribution',
    name: '/stake-distribution',
    component: () => import('/home/runner/work/jpool-webapp/jpool-webapp/src/pages/stake-distribution.vue'),
    /* no children */
  },
  {
    path: '/terms',
    name: '/terms',
    component: () => import('/home/runner/work/jpool-webapp/jpool-webapp/src/pages/terms.vue'),
    /* no children */
  },
  {
    path: '/validator-profit-calculator',
    name: '/validator-profit-calculator',
    component: () => import('/home/runner/work/jpool-webapp/jpool-webapp/src/pages/validator-profit-calculator.vue'),
    /* no children */
  },
  {
    path: '/validators',
    /* internal name: '/validators' */
    /* no component */
    children: [
      {
        path: '',
        name: '/validators/',
        component: () => import('/home/runner/work/jpool-webapp/jpool-webapp/src/pages/validators/index.vue'),
        /* no children */
      },
      {
        path: ':vote',
        /* internal name: '/validators/[vote]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/validators/[vote]/',
            component: () => import('/home/runner/work/jpool-webapp/jpool-webapp/src/pages/validators/[vote]/index.vue'),
            /* no children */
          },
          {
            path: 'report',
            name: '/validators/[vote]/report',
            component: () => import('/home/runner/work/jpool-webapp/jpool-webapp/src/pages/validators/[vote]/report.vue'),
            /* no children */
          },
          {
            path: 'stake-report',
            name: '/validators/[vote]/stake-report',
            component: () => import('/home/runner/work/jpool-webapp/jpool-webapp/src/pages/validators/[vote]/stake-report.vue'),
            /* no children */
          }
        ],
      }
    ],
  }
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

