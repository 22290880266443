import type { Cluster, PublicKeyInitData } from '@solana/web3.js'
import type { ExtendedCluster } from '~/store'
import { API_HC_URL, HC_API_JWT_TOKEN } from '~/config'

async function getHoldersClubConfig(): Promise<IHoldersClubConfig> {
  return fetch(`${API_HC_URL}/config`)
    .then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`)
      }
      return res.json()
    })
    .catch((error) => {
      throw error
    })
}

async function getPoolsInfo(service = 'meteora'): Promise<IHoldersClubPoolsInfo[]> {
  return fetch(`${API_HC_URL}/${service}/pools`)
    .then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`)
      }
      return res.json()
    })
    .catch((error) => {
      throw error
    })
}

async function getUserData(id: string, cluster: ExtendedCluster): Promise<IUserData> {
  return fetch(`${API_HC_URL}/user/${id}?cluster=${cluster}`)
    .then((res) => {
      if (res.status === 404) {
        return null
      }
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`)
      }
      return res.json()
    })
    .catch((error) => {
      throw error
    })
}

async function getUserDataByTelegramId(id: string): Promise<IUserData> {
  return fetch(`${API_HC_URL}/user/telegram/${id}`, {
    headers: {
      authorization: `Bearer ${HC_API_JWT_TOKEN}`,
    },
  })
    .then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`)
      }
      return res.json()
    })
    .catch((error) => {
      throw error
    })
}

async function getUserRewards(id: string): Promise<IUserRewards> {
  return fetch(`${API_HC_URL}/rewards/history?userId=${id}&page=1&limit=9999`)
    .then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`)
      }
      return res.json()
    })
    .catch((error) => {
      throw error
    })
}

async function getUserEvents(userId?: string, limit = 100): Promise<IUSerEvents> {
  const params: {
    limit: number
    userId?: string
  } = {
    limit,
  }
  if (userId) {
    params.userId = userId
  }

  const query = new URLSearchParams(params as any).toString()

  return fetch(`${API_HC_URL}/events?${query}`)
    .then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`)
      }
      return res.json()
    })
    .catch((error) => {
      throw error
    })
}

async function userRegister(params: {
  wallet: string
  credentialMint?: string
  cluster: ExtendedCluster
  referralCode: string }): Promise<{ id: string }> {
  return fetch(`${API_HC_URL}/user/register`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  })
    .then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`)
      }
      return res.json()
    })
    .catch((error) => {
      throw error
    })
}

async function userVerify(params: {
  userId: string
  credentialMint: string }): Promise<{ id: string }> {
  return fetch(`${API_HC_URL}/user/verify`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  })
    .then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`)
      }
      return res.json()
    })
    .catch((error) => {
      throw error
    })
}

async function getUserBalances(useId: string): Promise<IUserBalance[]> {
  return fetch(`${API_HC_URL}/balance?userId=${useId}`)
    .then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`)
      }
      return res.json()
    })
    .catch((error) => {
      throw error
    })
}

async function getDailyRewards(userId: string): Promise<IDailyRewards> {
  return fetch(`${API_HC_URL}/rewards/calculate?userId=${userId}`)
    .then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`)
      }
      return res.json()
    })
    .catch((error) => {
      throw error
    })
}

async function getUserReferrals(userId: string): Promise<IReferral[]> {
  return fetch(`${API_HC_URL}/referral/${userId}`)
    .then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`)
      }
      return res.json()
    })
    .catch((error) => {
      throw error
    })
}

async function userLinkWallet(params: {
  publicKey: string
  signature: string
  merge: boolean }) {
  const token = useStorage('accessToken', '')
  return fetch(`${API_HC_URL}/user/link/wallet`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.value}`,
    },
    body: JSON.stringify(params),
  })
    .then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`)
      }
      return res.json()
    })
    .catch((error) => {
      throw error
    })
}

export function useHoldersClubService() {
  return {
    getUserData,
    getPoolsInfo,
    getUserDataByTelegramId,

    userVerify,
    userRegister,

    getUserEvents,
    getUserRewards,
    getDailyRewards,
    getUserBalances,
    getUserReferrals,
    getHoldersClubConfig,

    userLinkWallet,
  }
}

export type RewardProviderConfigToken = {
  mint: string
  decimals: number
  cluster: Cluster
  name: string
  pool: string
  url: string
}

export type RewardProviderConfig = {
  tokens: Array<RewardProviderConfigToken>
  boostStartDate?: Date
  boostEndDate?: Date
  boostProgress?: number
  boostInitialRate?: number
  boostCurrentRate?: number
  url?: string
}

export type RewardProvider = {
  id: string
  rate: number
  config?: any
}

export type IHoldersClubConfig = {
  season: number
  tierThresholds: { [key: string]: number }
  rewardRate: number
  credentialValidUntil: number
  rewardProviders: Array<RewardProvider>
  credentialMetadataUrl: string
}

export type IUserData = {
  id: string
  cluster: Cluster
  wallet?: string
  tier: number
  pointsEarned: number
  pointsClaimed: number
  imageUrl: string
  credentialMint: string
  isVerified: boolean
  isActive: boolean
  createdAt: Date
  referralCode: string
  telegramUsername?: string
  telegramId?: string
  contactInfo: {
    [key: string]: string
  }
}

export type IReward = {
  id: number
  userId: string
  rewardProvider: string
  season: string
  amount: string
  comment: null
  createdAt: Date
}

export type IUserRewards = {
  data: IReward[]
  total: number
  perPage: number
  currentPage: number
  lastPage: number
}

export type IUserEvent = {
  id: number
  type: string
  title: string
  description: string
  discount: string
  promoCode: string
  validFrom: Date
  validUntil: Date
  isActive: boolean
  updatedAt: Date
  createdAt: Date
}

export type IUSerEvents = {
  total: number
  perPage: number
  currentPage: number
  lastPage: number
  data: IUserEvent[]
}

export type IUserBalance = {
  id: number
  userId: string
  rewardProvider: string
  rewardRate: number
  realAmount: number
  mint: PublicKeyInitData
  tokenAccount: PublicKeyInitData
  amount: string
  isActive: boolean
  lastTxBlockTime: number
  updatedAt: Date
  createdAt: Date
  decimals: number
  parentId?: number
}

export type IDailyRewards = {
  fromDate: Date
  toDate: Date
  rewards: {
    [key: string]: number
  }
}

export type IHoldersClubPoolsInfo = {
  address: string
  name: string
  type: string
  version: number
  tvl: number
  mints: Array<string>
  tokenAmounts: Array<number>
  lpMint: string
  lpDecimal: number
  lpSupply: number
}

export type IReferral = {
  id: string
  referrer: string
  level: number
  percent: number
}
