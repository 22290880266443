const emitter = useEmitter()

export const Analytics = {
  enabled: true,

  events: {
    track: 'Analytics::track',
  },

  enable() {
    this.enabled = true
  },

  disable() {
    this.enabled = false
  },

  track(event: TrackEventOptions) {
    if (!this.enabled) {
      return
    }
    emitter.emit(this.events.track, event)
  },

  handle(handler: (data: TrackEventOptions) => void) {
    if (!this.enabled) {
      return
    }
    emitter.on(this.events.track, handler)
  },
}

export type TrackEventOptions = {
  event: string
  category?: any
  action?: any
  label?: any
  value?: any
  [key: string]: any
}
