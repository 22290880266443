<script lang="ts" setup>
import darkLogo from '~/assets/img/logo/jpool-logo-dark.svg'
import logo from '~/assets/img/logo/jpool-logo.svg'

const authStore = useAuthStore()
const error = ref(false)
const password = ref('')

const logoUrl = computed(() => isDark.value ? darkLogo : logo)

function login() {
  if (!authStore.login(password.value)) {
    password.value = ''
    error.value = true
  }
}
</script>

<template>
  <q-layout :class="$style.layout">
    <q-page-container>
      <q-page class="window-height window-width column justify-center items-center">
        <div :class="$style.logo">
          <img :src="logoUrl" alt="" height="100">
        </div>
        <q-card class="shadow-0">
          <q-card-section>
            <q-form>
              <q-input
                v-model="password"
                :class="$style.input"
                :error="error"
                placeholder="Secret Code"
                type="password"
                outlined
              >
                <template #append>
                  <q-btn flat round @click="login">
                    GO
                  </q-btn>
                </template>
              </q-input>
            </q-form>
          </q-card-section>
        </q-card>
      </q-page>
      <theme-mode-selector class="absolute-top-right q-ma-md" />
    </q-page-container>
  </q-layout>
</template>

<style lang="scss" module>
.input {
  width: 240px;
  input {
    &::placeholder {
      color: #aaa;
    }
  }
}

.logo {
  margin-bottom: 1rem;
}
</style>
