const state = reactive({
  filters: <Array<string>>[],
})

export function useDefiFilter() {
  const addAndRemoveFilters = (coin: string): void => {
    const checkFilter = state.filters.filter(item => item === coin)

    if (checkFilter.length === 0) {
      state.filters.push(coin)
    } else {
      const coinIdx = state.filters.indexOf(coin)
      state.filters.splice(coinIdx, 1)
    }
  }

  const addToFilter = (coin: string): void => {
    if (coin === 'clean') {
      state.filters = []
      return
    }
    addAndRemoveFilters(coin)
  }

  return { defiFilters: state, addToFilter }
}
