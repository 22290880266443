export function useLoginApi() {
  const { axiosHcClient } = useHcApi()

  async function refreshToken(): Promise<string | undefined> {
    try {
      const _refreshToken = useStorage('refresh-token', '')
      const accessToken = useStorage('accessToken', '')

      const props = { token: _refreshToken.value || '' }
      const res = await axiosHcClient.post('auth/refresh', props)
      _refreshToken.value = res.data?.refreshToken
      accessToken.value = res.data?.accessToken
      return res.data?.accessToken
    } catch (error) {
      console.log(error)
    }
  }

  async function login() {
    try {
      const { data } = await axiosHcClient.get('user/me')
      return data
    } catch (error) {
      console.log(error)
    }
  }

  return {
    login,
    refreshToken,
  }
}
