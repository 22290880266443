import type { SendTransactionOptions, WalletName } from '@solana/wallet-adapter-base'
import type { Connection, Transaction, TransactionSignature, VersionedTransaction } from '@solana/web3.js'
import type Torus from '@toruslabs/solana-embed'
import type { TorusParams } from '@toruslabs/solana-embed'
import {
  BaseMessageSignerWalletAdapter,
  WalletAccountError,
  WalletConfigError,
  WalletConnectionError,
  WalletDisconnectionError,
  WalletError,
  WalletLoadError,
  WalletNotConnectedError,
  WalletNotReadyError,
  WalletPublicKeyError,
  WalletReadyState,
  WalletSendTransactionError, WalletSignMessageError,
  WalletSignTransactionError,
} from '@solana/wallet-adapter-base'
import { PublicKey } from '@solana/web3.js'

export type TorusWalletAdapterConfig = {
  params?: TorusParams
}

type TorusWindow = {
  torus?: Torus | null
} & Window

declare const window: TorusWindow

export const TorusWalletName = 'Torus' as WalletName<'Torus'>

export class TorusWalletAdapter extends BaseMessageSignerWalletAdapter {
  name = TorusWalletName
  url = 'https://tor.us'
  icon
    = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzMiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMyAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTYuNSIgY3k9IjE2IiByPSIxNiIgZmlsbD0iIzAzNjRGRiIvPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTExLjIxODYgOS40OTIxOUMxMC40NTM5IDkuNDkyMTkgOS44MzM5OCAxMC4xMTIxIDkuODMzOTggMTAuODc2OFYxMi40ODk4QzkuODMzOTggMTMuMjU0NSAxMC40NTM5IDEzLjg3NDQgMTEuMjE4NiAxMy44NzQ0SDEzLjY2ODRWMjIuODk3NkMxMy42Njg0IDIzLjY2MjMgMTQuMjg4MyAyNC4yODIyIDE1LjA1MyAyNC4yODIySDE2LjY2NkMxNy40MzA3IDI0LjI4MjIgMTguMDUwNiAyMy42NjIzIDE4LjA1MDYgMjIuODk3NlYxMi41MDE1QzE4LjA1MDYgMTIuNDk3NiAxOC4wNTA2IDEyLjQ5MzcgMTguMDUwNiAxMi40ODk4VjEwLjg3NjhDMTguMDUwNiAxMC4xMTIxIDE3LjQzMDcgOS40OTIxOSAxNi42NjYgOS40OTIxOUgxNS4wNTNIMTEuMjE4NloiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGQ9Ik0yMS4zMzc2IDEzLjg3NDRDMjIuNTQ3NyAxMy44NzQ0IDIzLjUyODcgMTIuODkzNCAyMy41Mjg3IDExLjY4MzNDMjMuNTI4NyAxMC40NzMyIDIyLjU0NzcgOS40OTIxOSAyMS4zMzc2IDkuNDkyMTlDMjAuMTI3NSA5LjQ5MjE5IDE5LjE0NjUgMTAuNDczMiAxOS4xNDY1IDExLjY4MzNDMTkuMTQ2NSAxMi44OTM0IDIwLjEyNzUgMTMuODc0NCAyMS4zMzc2IDEzLjg3NDRaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K'

  readonly supportedTransactionVersions = null

  private _connecting: boolean
  private _wallet: Torus | null
  private _publicKey: PublicKey | null
  private readonly _params: TorusParams
  private _readyState: WalletReadyState
    = typeof window === 'undefined' || typeof document === 'undefined'
      ? WalletReadyState.Unsupported
      : WalletReadyState.Loadable

  constructor(params?: TorusWalletAdapterConfig) {
    super()
    this._connecting = false
    this._wallet = null
    this._publicKey = null
    this._params = { showTorusButton: false, ...params }
  }

  get publicKey() {
    return this._publicKey
  }

  get connecting() {
    return this._connecting
  }

  override get connected() {
    return !!this._wallet?.isLoggedIn
  }

  get readyState() {
    return this._readyState
  }

  async connect(): Promise<void> {
    try {
      if (this.connected || this.connecting) {
        return
      }
      if (this._readyState !== WalletReadyState.Loadable) {
        throw new WalletNotReadyError()
      }

      this._connecting = true

      let TorusClass: typeof Torus
      try {
        TorusClass = (await import('@toruslabs/solana-embed')).default
      } catch (error: any) {
        throw new WalletLoadError(error?.message, error)
      }

      let wallet: Torus
      try {
        wallet = window.torus || new TorusClass()
      } catch (error: any) {
        throw new WalletConfigError(error?.message, error)
      }

      if (!wallet.isInitialized) {
        try {
          await wallet.init(this._params)
        } catch (error: any) {
          throw new WalletConnectionError(error?.message, error)
        }
      }

      let accounts: string[]
      try {
        accounts = await wallet.login()
      } catch (error: any) {
        throw new WalletAccountError(error?.message, error)
      }

      let publicKey: PublicKey
      try {
        publicKey = new PublicKey(accounts[0]!)
      } catch (error: any) {
        throw new WalletPublicKeyError(error?.message, error)
      }

      this._wallet = wallet
      this._publicKey = publicKey

      this.emit('connect', publicKey)
    } catch (error: any) {
      this.emit('error', error)
      throw error
    } finally {
      this._connecting = false
    }
  }

  async disconnect(): Promise<void> {
    const wallet = this._wallet
    if (wallet) {
      this._wallet = null
      this._publicKey = null

      try {
        if (wallet.isLoggedIn) {
          await wallet.cleanUp()
        }
      } catch (error: any) {
        this.emit('error', new WalletDisconnectionError(error?.message, error))
      }
    }

    this.emit('disconnect')
  }

  override async sendTransaction(
    transaction: Transaction,
    connection: Connection,
    options: SendTransactionOptions = {},
  ): Promise<TransactionSignature> {
    try {
      const wallet = this._wallet
      if (!wallet) {
        throw new WalletNotConnectedError()
      }

      try {
        const { signers, ...sendOptions } = options

        transaction = await this.prepareTransaction(transaction, connection, sendOptions)

        signers?.length && transaction.partialSign(...signers)

        sendOptions.preflightCommitment = sendOptions.preflightCommitment || connection.commitment

        const { signature } = await wallet.signAndSendTransaction(transaction, sendOptions)
        return signature
      } catch (error: any) {
        if (error instanceof WalletError) {
          throw error
        }
        throw new WalletSendTransactionError(error?.message, error)
      }
    } catch (error: any) {
      this.emit('error', error)
      throw error
    }
  }

  async signTransaction<T extends Transaction | VersionedTransaction>(
    transaction: T,
  ): Promise<T> {
    try {
      const wallet = this._wallet
      if (!wallet) {
        throw new WalletNotConnectedError()
      }

      try {
        return (await wallet.signTransaction(transaction)) as T
      } catch (error: any) {
        throw new WalletSignTransactionError(error?.message, error)
      }
    } catch (error: any) {
      this.emit('error', error)
      throw error
    }
  }

  override async signAllTransactions<T extends Transaction | VersionedTransaction>(
    transactions: T[],
  ): Promise<T[]> {
    try {
      const wallet = this._wallet
      if (!wallet) {
        throw new WalletNotConnectedError()
      }

      try {
        return (await wallet.signAllTransactions(transactions)) as T[]
      } catch (error: any) {
        throw new WalletSignTransactionError(error?.message, error)
      }
    } catch (error: any) {
      this.emit('error', error)
      throw error
    }
  }

  async signMessage(message: Uint8Array): Promise<Uint8Array> {
    try {
      const wallet = this._wallet
      if (!wallet) {
        throw new WalletNotConnectedError()
      }

      try {
        return await wallet.signMessage(message)
      } catch (error: any) {
        throw new WalletSignMessageError(error?.message, error)
      }
    } catch (error: any) {
      this.emit('error', error)
      throw error
    }
  }
}
