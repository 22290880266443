import { defineStore } from 'pinia'

export const useAuthStore = defineStore('auth', () => {
  const authPassword = String(import.meta.env.VITE_PASSWORD_PROTECT ?? '')
  const password = useLocalStorage<string>('password', null)
  const isAuthenticated = computed(() => password.value === authPassword)
  const isEnabled = computed(() => authPassword.length > 0)

  return {
    isEnabled,
    isAuthenticated,
    login(pass: string) {
      password.value = pass
      return isAuthenticated.value
    },
  }
})
