import type { UserModule } from '~/types'
import mixpanel from 'mixpanel-browser'
import { MIXPANEL_TOKEN } from '~/config'

export const install: UserModule = ({ app }) => {
  if (!MIXPANEL_TOKEN) {
    return
  }

  mixpanel.init(MIXPANEL_TOKEN, {
    track_pageview: true,
    ignore_dnt: true,
    debug: !import.meta.env.PROD,
  })

  app.provide('mixpanel', mixpanel)

  Analytics.handle(({ event, ...data }) => {
    mixpanel.track(event, data)
  })
}
