import type { AxiosInstance } from 'axios'
import axios from 'axios'
import { API_HC_URL } from '~/config'

let axiosHcClient: AxiosInstance

export function initHcApi() {
  axiosHcClient = axios.create({
    baseURL: API_HC_URL,
  })

  const token = useStorage('accessToken', '')
  const { refreshToken } = useLoginApi()

  const MAX_RETRIES = 2

  axiosHcClient.interceptors.request.use(
    (request) => {
      request.headers.Authorization = `Bearer ${token.value}`
      debug('Request', request.url, request.data, request.params)
      return request
    },
    (error) => {
      return Promise.reject(error)
    },
  )

  axiosHcClient.interceptors.response.use(
    (response) => {
      debug('Response', response.data)
      const { code, error } = response.data
      if (error) {
        return Promise.reject(new Error(`code: ${code}, message: ${error}`))
      }
      return response
    },
    async (error) => {
      const { response } = error
      const originalRequest = error.config

      originalRequest._retryCount = originalRequest._retryCount || 0

      if (response.data.message === 'Invalid token') {
        throw new Error('Invalid token')
      }

      if ((response.status === 401 || response.data.message === 'TokenExpiredError') && originalRequest._retryCount < MAX_RETRIES) {
        originalRequest._retryCount += 1

        const accessToken = await refreshToken()

        if (!accessToken) {
          throw new Error('Failed to refresh token')
        }

        originalRequest.headers.Authorization = `Bearer ${accessToken}`
        return axiosHcClient(originalRequest)
      }

      debug('Error', response.data)
      throw error
    },
  )
}

export function useHcApi() {
  if (!axiosHcClient && !import.meta.env.SSR) {
    throw new Error('axiosClient is not initialized, please use `initApi` first')
  }
  return { axiosHcClient }
}

function debug(...args: any[]) {
  import.meta.env.DEV && console.log('[API]', args)
}
