import type { TransactionInstruction } from '@solana/web3.js'
import { useWallet } from '@solana/wallet-adapter-vue'
import { ComputeBudgetProgram } from '@solana/web3.js'
import { defineStore } from 'pinia'
import { solToLamports } from '~/utils'

import {
  BASE_PRIORITY_FEE,
  DEFAULT_COMPUTE_UNITS,
  DEFAULT_EXACT_FEE,
  MICRO_LAMPORTS_PER_LAMPORT,
  MULTIPLIER_COMPUTE_UNITS,
  PriorityLevel,
  PriorityMode,
} from './constants'
import { estimatePrioritizationFee, getSimulationComputeUnits } from './utils'

export { default as PriorityFee } from './components/PriorityFee.vue'

export const usePriorityFee = defineStore('priority-fee', () => {
  const mode = useLocalStorage<PriorityMode>('priorityMode', PriorityMode.MaxCap)
  const level = useLocalStorage<PriorityLevel>('priorityLevel', PriorityLevel.Turbo)
  const exactFee = useLocalStorage<number>('priorityFee', DEFAULT_EXACT_FEE)

  const baseFee = computed(() => {
    return BASE_PRIORITY_FEE[level.value] ?? 0
  })

  return {
    mode,
    level,
    exactFee,
    baseFee,
  }
})

/**
 * Enhances the provided instructions with priority fee configurations.
 */
export async function withPriorityFees({ computeUnits, instructions }: {
  computeUnits?: number
  instructions: TransactionInstruction[]
}): Promise<TransactionInstruction[]> {
  const connectionStore = useConnectionStore()
  const priorityFee = usePriorityFee()
  const wallet = useWallet()
  const { publicKey } = wallet

  let estimate: number
  let units = computeUnits ?? DEFAULT_COMPUTE_UNITS

  if (!computeUnits && publicKey.value) {
    const unitsEstimated = await getSimulationComputeUnits(
      connectionStore.connection,
      instructions,
      publicKey.value,
      [],
    )
    if (unitsEstimated) {
      units = Math.ceil(unitsEstimated * MULTIPLIER_COMPUTE_UNITS) // + ADDITIONAL_COMPUTE_UNITS
    }
    console.log('[priority] Estimated CU:', `${unitsEstimated} / ${units}`)
  }

  // Calculate exact fee in micro-lamports
  const exactFeeMicroLamports = solToLamports(priorityFee.exactFee) * MICRO_LAMPORTS_PER_LAMPORT
  const cuPriceMax = Math.ceil(exactFeeMicroLamports / units)

  // Determine the compute unit price based on the priority mode
  if (priorityFee.mode === PriorityMode.ExactFee) {
    estimate = cuPriceMax
  } else {
    const estimatedCuPrice = await estimatePrioritizationFee(
      connectionStore.connection,
      instructions,
      priorityFee.baseFee,
    )
    console.log('[priority] Estimated CU price (mL):', estimatedCuPrice)
    estimate = Math.min(estimatedCuPrice, cuPriceMax)
  }

  console.log('[priority] Used CU price (mL):', estimate)
  console.log('[priority] Estimated prioritization fee:', (estimate * units) / MICRO_LAMPORTS_PER_LAMPORT)

  return [
    ComputeBudgetProgram.setComputeUnitLimit({ units }),
    ComputeBudgetProgram.setComputeUnitPrice({ microLamports: estimate }),
    ...instructions,
  ]
}

export * from './constants'
