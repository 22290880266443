function initStorageVersion() {
  if (!isClient) {
    return
  }
  const appVersion = import.meta.env.VITE_STORAGE_VERSION
  const localVersion = localStorage.getItem('storage-version')
  if (!localVersion || appVersion !== localVersion) {
    localStorage.clear()
    localStorage.setItem('storage-version', appVersion)
  }
}

export function useStorageVersion() {
  return { initStorageVersion }
}
